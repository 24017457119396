
(function($){

    //* Config the modal
    function initPopUp() {

        const modal   = $('#popup');
        const body    = $('body');
        const close   = $('[data-close], #popup .content a');

        let showModal = false;
        const modalKey  = 'modal';
        const modalStorage = ! localStorage.getItem(modalKey) ? 'empty' : JSON.parse(localStorage.getItem(modalKey));

        if(popup.enabled) {

            const timeNow       = new Date().getTime();
            const Midnight      = new Date().setUTCHours(0, 0, 0, 0);
            const inBetween     = Math.round((timeNow - modalStorage['shown']) / 1000);
            const lastEdited    = modalStorage['timestamp'];

            //Enable based on common conditions
            if ( modalStorage == 'empty'
                || popup.days && inBetween > popup.days * 86400
                || popup.timestamp != lastEdited
            ) {
                showModal = true
            }

            //Disable on admin pages
            if(body.hasClass('single-admin')) {
                showModal = false
            }

            //Disable base on timetframe
            if(popup.show_from || popup.show_until) {

                if( 
                    parseInt(popup.show_from) > Midnight ||
                    Midnight > parseInt(popup.show_until)
                ) {
                    showModal = false
                }

            }

            if ( showModal ) {

                //Show modal
                show(modal),

                //Else hide modal
                setTimeout(()=>{
                    close.click(function() {
                        hide(modal, modalKey)
                    })
                }, 100);
            }

            //Close on click outside
            if(popup.close_outside) {
                $('body').on('click',function(event){
                    if(!$(event.target).parents('.modal').length){
                        hide(modal, modalKey)
                    } 
                });
            }
            
        }

    }

    //* Show the modal
    function show(modal) {
        $('body').addClass('modal-open');
        modal.addClass('active');
        modal.attr("aria-hidden","false");
    }

    //* Hide the modal
    function hide(modal, modalKey) {
        $('body').removeClass('modal-open');
        modal.removeClass('active');
        modal.attr("aria-hidden","true");

        localStorage.setItem(modalKey, JSON.stringify({
            'shown'     : (new Date().getTime()),
            'timestamp' : popup.timestamp
        }));
    }

    //* Start the modal
    initPopUp();


})(jQuery);


